import { Container } from "@mui/material";
import * as React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import DeployAutoWebsite from "./common-functionalities/DeployAutoWebsite";
import { useSelector } from "react-redux";

function getCookie(name) {
  const cookieString = document.cookie;
  const cookies = cookieString.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    // Check if this cookie name matches the name parameter
    if (cookie.startsWith(name + "=")) {
      // Return the cookie value
      return cookie.substring(name.length + 1);
    }
  }
  // If cookie not found, return null
  return null;
}

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          color="success"
          sx={{
            height: "20px",
            borderRadius: "85px",
            backgroundColor: "#ffd3b0",
            ">*": {
              backgroundImage:
                "linear-gradient(180deg, #dd6c11, #f0850a) !important",
            },
          }}
          variant="determinate"
          {...props}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          color="text.secondary"
        >{`${props.value.toFixed(1)}%`}</Typography>
      </Box>
    </Box>
  );
}

function LinearWithValueLabel({ progress }) {
  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgressWithLabel value={progress} />
    </Box>
  );
}

const webLink = "https://auto.nurhesen.click/";
function fetchWithTimeout(fetchArgs, timeout) {
  return Promise.race([
    fetch(...fetchArgs),
    new Promise((_, reject) =>
      setTimeout(() => reject(new Error("Timeout")), timeout),
    ),
  ]);
}
const DeployPage = () => {
  const [isStartingCookie, setIsStartingCookie] = React.useState(
    getCookie("isStarting"),
  );

  const [progress, setProgress] = React.useState(0);
  const [rate, setRate] = React.useState(0.1);
  const [speed, setSpeed] = React.useState(100);
  const [toggle, setToggle] = React.useState(false);
  const [unreachable, setUnreachable] = React.useState(true);
  const [sentRequest, setSentRequest] = React.useState(false);

  const deploymentStarted = useSelector(
    (store) => store.deploy.deploymentStarted,
  );
  // const deployLoading = useSelector((store) => store.deploy.loading);

  const slowDown = () => {
    if (unreachable) {
      if (progress > 95) {
        setRate(0);
      } else if (progress > 90) {
        setRate(0.001);
      } else if (progress > 80) {
        setRate(0.01);
      }
    }
  };

  React.useEffect(() => {
    // Function to handle when the cookie changes
    function handleCookieChange() {
      setIsStartingCookie(getCookie("isStarting"));
    }

    // Add event listener to listen for changes in cookies
    window.addEventListener("change", handleCookieChange);

    // Cleanup: Remove event listener to prevent memory leaks
    return () => {
      window.removeEventListener("change", handleCookieChange);
    };
  }, []); // Empty dependency array means this effect runs only once, similar to componentDidMount

  React.useEffect(() => {
    setIsStartingCookie(getCookie("isStarting"));
    // Function to handle when the cookie changes
  }, [deploymentStarted]); // Empty dependency array means this effect runs only once, similar to componentDidMount

  // Function to get a cookie by name

  React.useEffect(() => {
    if (unreachable) {
      setTimeout(() => {
        function checkWebsite(url) {
          fetchWithTimeout([url, { method: "HEAD" }], 5000)
            .then((response) => {
              setSentRequest(true);
              if (response.ok) {
                console.log("Website is active");

                setUnreachable(false);
                setRate(0.1);
                setSpeed(10);
                if (progress < 55) {
                  setSpeed(1);
                }
              } else {
                setSentRequest(true);
                console.log("Website is not active");
              }
            })
            .catch((error) => {
              setSentRequest(true);
              console.log("Website is not active");
            });
        }

        // Example usage
        checkWebsite(webLink);
        // (async () => {
        //   try {
        //     const data = await fetchWithTimeout(webLink, 5000);
        //     console.log(data);
        //   } catch {
        //     console.log("fail");
        //   }
        // })();
        // Update count every second

        setToggle(!toggle);
      }, 2000);
    }
  }, [toggle]);

  React.useEffect(() => {
    if (!sentRequest) {
      setToggle(!toggle);
    }
    if (!(!isStartingCookie && sentRequest && unreachable) && sentRequest) {
      slowDown();
      if (progress < 99.99) {
        const timer = setTimeout(() => {
          setProgress(progress + rate);
        }, speed);

        return () => {
          clearTimeout(timer);
        };
      } else {
        setProgress(100);
        setRate(0);

        window.open(webLink, "_blank");
      }
    }
  }, [progress, isStartingCookie, sentRequest, unreachable]);

  React.useEffect(() => {
    console.log(isStartingCookie, sentRequest, unreachable);
  }, [isStartingCookie, sentRequest, unreachable]);

  return (
    <Container
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        ">*": {
          my: "20px",
        },
      }}
      fixed
    >
      {!sentRequest && <h1>Checking server status...</h1>}
      {!isStartingCookie && sentRequest && unreachable && (
        <>
          <h1>Server is not deployed</h1>
          <DeployAutoWebsite />
        </>
      )}
      {sentRequest &&
        !(!isStartingCookie && sentRequest && unreachable) &&
        progress < 100 && (
          <>
            <h1>Please Wait. We are deploying the website...</h1>
          </>
        )}

      {sentRequest && (
        <p>
          Keep in mind that the website will only be active for 10 minutes once
          it is deployed!
        </p>
      )}

      {!sentRequest && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress
            sx={{
              height: "20px",
              borderRadius: "85px",
              backgroundColor: "#ffd3b0",
              ">*": {
                backgroundImage:
                  "linear-gradient(180deg, #dd6c11, #f0850a) !important",
              },
            }}
          />
        </Box>
      )}
      {sentRequest && !(!isStartingCookie && sentRequest && unreachable) && (
        <LinearWithValueLabel progress={progress} />
      )}

      {progress === 100 && (
        <>
          {" "}
          <h1>Successfully Deployed</h1>
          <Box
            sx={{
              display: "grid",
              height: "fit-content",
              gridRowGap: "20px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Link
              href={webLink}
              sx={{
                color: "orange",
                fontSize: "min(calc(1rem + 3vw), 2rem)",
                textDecorationColor: "#ffa500a6",
                width: "fit-content",
                margin: "auto",
              }}
            >
              {webLink}
            </Link>
            <Link
              href={webLink + "admin/"}
              sx={{
                color: "orange",
                fontSize: "min(calc(1rem + 3vw), 2rem)",
                textDecorationColor: "#ffa500a6",
                width: "fit-content",
                margin: "auto",
              }}
            >
              {webLink + "admin/"}
            </Link>
          </Box>
          <Box
            sx={{
              display: "grid",
              height: "fit-content",
              gridRowGap: "15px",
              backgroundColor: "#e97c0c4f",
              padding: "20px 60px",
              borderRadius: "14px",
            }}
          >
            <Box as="p" sx={{ margin: "auto", padding: "0" }}>
              Username: admin
            </Box>
            <Box as="p" sx={{ margin: "auto", padding: "0" }}>
              Password: admin
            </Box>
          </Box>
        </>
      )}
    </Container>
  );
};

export default DeployPage;
