import React, { useEffect, useRef } from "react";

function SpriteAnimation() {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const sprite = new Image();
    sprite.src =
      "https://2d.nurhesen.click/204f4eeeb1c03d9d4cabcb963747f2fd.png"; // Provide path to your sprite image

    const originalSpriteWidth = 40; // Original width of each frame in the sprite
    const originalSpriteHeight = 40; // Original height of the sprite
    const scale = 1; // Scale factor for the sprite

    const spriteWidth = originalSpriteWidth * scale; // Width of each frame after scaling
    const spriteHeight = originalSpriteHeight * scale; // Height of the sprite after scaling
    const frameCount = 3; // Number of frames in the sprite
    let currentFrame = 0; // Index of the current frame
    const frameRate = 100; // Frame rate in milliseconds

    // Function to update the animation
    // Function to update the animation
    function updateAnimation() {
      // Clear the canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Save the current transformation state
      ctx.save();

      // Mirror the canvas horizontally
      ctx.scale(-1, 1);
      ctx.translate(-spriteWidth, 0); // Translate to adjust for mirroring

      // Draw the current frame of the sprite
      ctx.drawImage(
        sprite,
        currentFrame * originalSpriteWidth,
        0,
        originalSpriteWidth,
        originalSpriteHeight,
        0,
        0,
        spriteWidth,
        spriteHeight,
      );

      // Restore the previous transformation state
      ctx.restore();

      // Move to the next frame
      currentFrame = (currentFrame + 1) % frameCount;
    }

    // Start the animation loop
    function animate() {
      setInterval(updateAnimation, frameRate);
    }

    // Load sprite and start animation when it's loaded
    sprite.onload = animate;

    // Clear the interval when component unmounts
    return () => clearInterval();
  }, []);

  return (
    <canvas
      ref={canvasRef}
      style={{ filter: "grayscale(0.8)" }}
      width={40}
      height={40}
    />
  ); // Set canvas size to accommodate scaled sprite
}

export default SpriteAnimation;
