import "./styles.css";
function TwoDResume({ getWindowDimensions }) {
  const winWidth = getWindowDimensions().width;
  return (
    <div
      className="iframe-container"
      style={
        winWidth < 600
          ? { marginTop: "56px", height: "calc(100vh - 56px)" }
          : {}
      }
    >
      <iframe
        src="https://2d.nurhesen.click"
        title="Example Website"
        className="iframe"
        frameBorder="0"
        allowfullscreen
      ></iframe>
    </div>
  );
}

export default TwoDResume;
