import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import { useState } from "react";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import NightsStayIcon from "@mui/icons-material/NightsStay";

export default function DarkMode({ setModeNameMain }) {
  const [modeName, setModeName] = useState("");
  React.useEffect(() => {
    if (modeName !== "") {
      // Add a condition to check if currentPage is not empty
      localStorage.setItem("modeName", modeName);
    }
    setModeNameMain(modeName);
  }, [modeName]);

  React.useEffect(() => {
    const lastMode = localStorage.getItem("modeName");
    if (lastMode) {
      setModeName(lastMode);
    } else {
      setModeName("light");
    }
    if (lastMode) {
      if (lastMode == "light") {
        document.documentElement.style.setProperty("--body-bg", "white");
        document.documentElement.style.setProperty("--body-color", "black");
        document.documentElement.style.setProperty(
          "--left-bg",
          "linear-gradient(45deg, #060a0f, #253445, #060a0f)",
        );
        document.documentElement.style.setProperty("--left-color", "white");
      } else {
        document.documentElement.style.setProperty(
          "--body-bg",
          "rgb(17 17 17)",
        );
        document.documentElement.style.setProperty("--body-color", "white");
        document.documentElement.style.setProperty(
          "--left-bg",
          "linear-gradient(45deg, black, #262626, black)",
        );
        document.documentElement.style.setProperty("--left-color", "white");
      }
    }
  }, []);

  const changeMode = () => {
    if (modeName == "dark") {
      document.documentElement.style.setProperty("--body-bg", "white");
      document.documentElement.style.setProperty("--body-color", "black");
      document.documentElement.style.setProperty(
        "--left-bg",
        "linear-gradient(45deg, #060a0f, #253445, #060a0f)",
      );
      document.documentElement.style.setProperty("--left-color", "white");

      setModeName("light");
    } else {
      document.documentElement.style.setProperty("--body-bg", "rgb(17 17 17)");
      document.documentElement.style.setProperty("--body-color", "white");
      document.documentElement.style.setProperty(
        "--left-bg",
        "linear-gradient(45deg, black, #262626, black)",
      );
      document.documentElement.style.setProperty("--left-color", "white");

      setModeName("dark");
    }
  };

  return (
    <Box
      position={"fixed"}
      right={"90px"}
      bottom="14px"
      width="fit-content"
      height="fit-content"
      zIndex={999}
      sx={{
        "@media screen and (max-width: 480px)": {
          /* CSS rules for mobile devices with a maximum width of 600px */
          /* For example: */
          right: "70px",

          bottom: "5px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "fit-content",
          height: "fit-content",
          alignItems: "center",
          justifyContent: "center",
          bgcolor: "transparent",
          color: "text.primary",
          borderRadius: 1,

          p: 0,
        }}
      >
        <IconButton onClick={changeMode} color="inherit">
          {modeName === "dark" ? (
            <WbSunnyIcon
              sx={{
                width: "3rem",
                height: "3rem",
                color: "orange",
                animation: "sunSpinAnimation 13s linear forwards infinite ",
              }}
            />
          ) : (
            <NightsStayIcon
              sx={{
                width: "3rem",
                height: "3rem",
                color: "teal",
                animation: "blurAnimation 13s infinite",
              }}
            />
          )}
        </IconButton>
      </Box>
    </Box>
  );
}
