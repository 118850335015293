import { Box } from "@mui/material";

function Languages({ data }) {
  return (
    <div>
      <h1>Languages</h1>{" "}
      <table>
        <tbody>
          {data.map(({ title, proficiency, flag }, index) => {
            return (
              <Box
                key={index}
                display={"grid"}
                gridTemplateColumns={"auto auto auto"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                columnGap={"10px"}
              >
                <img
                  width={"50px"}
                  style={{ borderRadius: "6px" }}
                  src={"/flags/" + flag}
                />

                <Box component="h2">{title}</Box>

                <Box component="h3" fontWeight={"300"}>
                  ({proficiency})
                </Box>
              </Box>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default Languages;
