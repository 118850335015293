function ExtraSkills({ data }) {
  return (
    <div>
      {data.map(({ title, descriptions }) => {
        return (
          <>
            <h1>{title}</h1>
            <br />
            {descriptions.map((description) => (
              <>
                <p>{description}</p>
                <br />
              </>
            ))}
          </>
        );
      })}
    </div>
  );
}

export default ExtraSkills;
