function EducationAndExperience({ data }) {
  return (
    <div>
      <h2>Experience</h2>
      {data.experience.map(({ position, company }, index) => {
        return (
          <p key={index}>
            {position}
            {company && (
              <span>
                {" "}
                {company.link ? (
                  <>
                    at <a href={company.link}>{company.title}</a>
                  </>
                ) : (
                  "as " + company.title
                )}
              </span>
            )}
          </p>
        );
      })}

      <h2>University</h2>
      <p>{data.university}</p>

      <h2>School</h2>
      <p>{data.school}</p>
    </div>
  );
}

export default EducationAndExperience;
