import * as React from "react";

const Ec2Icon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 50 50"
    {...props}
  >
    <defs>
      <linearGradient
        id="a"
        x1={34.49}
        x2={62.49}
        y1={26.5}
        y2={26.5}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="white" />
        <stop offset={1} stopColor="#ffffffa6" />
      </linearGradient>
      <linearGradient
        xlinkHref="#a"
        id="b"
        x1={12.49}
        x2={40.49}
        y1={48.5}
        y2={48.5}
      />
      <linearGradient
        xlinkHref="#a"
        id="c"
        x1={21.5}
        x2={53.5}
        y1={37.5}
        y2={37.5}
      />
    </defs>
    <title>{"icon-aws-amazon-ec2"}</title>
    <path
      d="M60.49 40.5h-5v-2h5v-24h-24v5h-2v-5a2 2 0 0 1 2-2h24a2 2 0 0 1 2 2v24a2 2 0 0 1-2 2Z"
      style={{
        fill: "url(#a)",
      }}
      transform="translate(-12.49 -12.5)"
    />
    <path
      d="M38.49 62.5h-24a2 2 0 0 1-2-2v-24a2 2 0 0 1 2-2h5v2h-5v24h24v-5h2v5a2 2 0 0 1-2 2Z"
      style={{
        fill: "url(#b)",
      }}
      transform="translate(-12.49 -12.5)"
    />
    <path
      d="M53.5 28.5v-2h-3a2.181 2.181 0 0 0-2-2v-3h-2v3h-3v-3h-2v3h-3v-3h-2v3h-3v-3h-2v3h-3v-3h-2v3a2.181 2.181 0 0 0-2 2h-3v2h3v3h-3v2h3v3h-3v2h3v3h-3v2h3v3h-3v2h3a2.181 2.181 0 0 0 2 2v3h2v-3h3v3h2v-3h3v3h2v-3h3v3h2v-3h3v3h2v-3a2.181 2.181 0 0 0 2-2h3v-2h-3v-3h3v-2h-3v-3h3v-2h-3v-3h3v-2h-3v-3Zm-5 19.82a.181.181 0 0 1-.18.18H26.68a.181.181 0 0 1-.18-.18V26.68a.181.181 0 0 1 .18-.18h21.64a.181.181 0 0 1 .18.18Z"
      style={{
        fill: "url(#c)",
      }}
      transform="translate(-12.49 -12.5)"
    />
  </svg>
);
export default Ec2Icon;
