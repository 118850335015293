import { Container, Box, Typography } from "@mui/material";
import * as React from "react";
import { PlayCircleOutline, Code, Language } from "@mui/icons-material";
import YouTubeIcon from '@mui/icons-material/YouTube';
import GitHubIcon from '@mui/icons-material/GitHub';
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

const DemosPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const projectTitle = queryParams.get("projectTitle") || "Project Title";

  return (
    <Container
      sx={{
        height: "auto",
        width: "auto",

        minWidth: "100vw",
        minHeight: "100vh",

        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: "url(/bg-demos.jpg)",
        backgroundEepeat: "no-repeat",
        backgroundSize: "cover",
        color: "#fff"
      }}
      fixed
    >
         <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          zIndex: 1,
          backgroundImage: 'linear-gradient(45deg, #060a0f, #253445, #060a0f)', // Semi-transparent white layer
          opacity: 0.5,
        }}
      />
      
      {/* Title component */}
      <Box zIndex={2}       sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",}}>

      <Typography variant="h4" fontWeight={'bold'} textAlign={'center'} my="30px">{projectTitle}</Typography>

<Box className="sections"       sx={{
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  ">*":{
    mx: 2,
    border: '1px solid #84beff',
    textDecoration:'none',
    ">*":{
      width: 300,
      height: 700,
      ":hover":{
        backgroundImage: 'none',

      }
    }
  }

}}>
  <Link to={queryParams.get("videoLink") || "#"}>
    <Box
      sx={{
        backgroundImage: "linear-gradient(45deg, black, #474747, black)",
        color: "white",
        p: 2,
   
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <YouTubeIcon sx={{ fontSize: 100, color: "red" }} />
      <Typography variant="h6">Watch Youtube walkthrough</Typography>
    </Box>
  </Link>
  <Link to={queryParams.get("codeLink") || "#"}>
    <Box
      sx={{
        backgroundImage: "linear-gradient(45deg, gray, #e7e7e7, gray)",
        color: "white",
        p: 2,
  
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <GitHubIcon sx={{ fontSize: 100, color: "black" }} />
      <Typography variant="h6" color={"black"} >Check Github repository</Typography>
    </Box>
  </Link>
  <Link to={queryParams.get("websiteLink") || "#"}>
    <Box
      sx={{
        backgroundImage: "linear-gradient(45deg, #060a0f, #253445, #060a0f)",
        color: "white",
        p: 2,

        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Language sx={{ fontSize: 100, color: "#84beff" }} />
      <Typography variant="h6">Go to website</Typography>
    </Box>
  </Link>
</Box>
      </Box>
      
    </Container>
  );
};

export default DemosPage;
