import React from "react";
import { Box } from "@mui/material";
import DeployAutoWebsite from "../common-functionalities/DeployAutoWebsite";
import PropTypes from "prop-types"; // Import PropTypes
import LanguageIcon from '@mui/icons-material/Language';
import YouTubeIcon from '@mui/icons-material/YouTube';
import GitHubIcon from '@mui/icons-material/GitHub';
import BusinessIcon from '@mui/icons-material/Business';


// Icons for Live, Video and Github links
const siteIcons = {
  live: <LanguageIcon sx={{color: '#0061d0'}}/>,
  website: <LanguageIcon sx={{color: '#0061d0'}} />,
  video: <YouTubeIcon sx={{color: 'red'}} />,
  github: <GitHubIcon />,
  "live example": <LanguageIcon sx={{color: '#0061d0'}}/>,
  "working example": <YouTubeIcon sx={{color: 'red'}} />,
};


const deployList = {
  DeployAutoWebsite: () => <DeployAutoWebsite />,
};

/**
 * Portfolio component displays a list of projects.
 * @param {Object} props - Component props.
 * @param {Array} props.data - List of project data.
 * @return {JSX.Element} JSX representation of the component.
 */
function Portfolio({ data }) {
  return (
    <div>
      <h1>Projects</h1>
      {data.map(
        (
          {
            title,
            tech,
            type,
            logo,
            links,
            sitePurpose,
            functionalities,
            technologies,
            drop_shadow
          },
          index,
        ) => {
          return (
            <div key={index}>
              <Box as='h2' display={'grid'} gridAutoFlow={'column'} justifyContent={'center'} alignItems={'flex-end'} columnGap={'20px'} width={'fit-content'}>
                <div>{index + 1}.</div>
                <div>
                {logo ? <Box as='img' src={logo} alt={title} sx={{
                  width: "max-content",
                  height: "max-content",
                  maxHeight: "70px",
                  maxWidth: "400px",
                  objectFit: "contain",
                  filter: drop_shadow||"drop-shadow(2px 2px 1px black)"
                }} /> : title}
                </div>
                

              </Box>
              <div>
                {tech && (
                  <>
                    <span>{tech}</span>-
                  </>
                )}

                {type && (
                  <Box display={'grid'} justifyContent={'center'} alignItems={'center'} width={'fit-content'} gridAutoFlow={'column'} columnGap={'8px'}>
                  {type.toLowerCase() === "commercial"&&<div><BusinessIcon sx={{color: '#e49300'}} /></div>} <h4>{type}</h4>
                  </Box>
                )}
              </div>
              {links.map(({ name, link, deploy }, linkIndex) => {
                return (
                  <Box
                    key={linkIndex}
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "auto auto",
                      width: "fit-content",
                      justifyContent: "center",
                      alignItems: "center",
                      gridColumnGap: "10px",
                      maxWidth: "70vw",
                      "@media screen and (max-width: 816px)": {
                        /* CSS rules for mobile devices with a maximum width of 600px */
                        /* For example: */
                        gridTemplateColumns: "auto",
                      },
                    }}
                  >
                    
                    <Box display={'grid'} gridAutoFlow={'column'} columnGap="10px" justifyContent={'center'} alignItems={'center'}>{siteIcons[name.toLowerCase()] && <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>{siteIcons[name.toLowerCase()]}</Box>}<Box>{name}:</Box></Box>
                    {link && (
                      <Box>
                        {" "}
                        <a href={link}>{link}</a>
                      </Box>
                    )}
                    {deploy && <Box width={"0px"}>{deployList[deploy]()}</Box>}
                  </Box>
                );
              })}
              {sitePurpose && (
                <>
                  <h4>Site purpose</h4>
                  <p>{sitePurpose}</p>
                </>
              )}
              {functionalities.length > 0 && (
                <>
                  <h4>Functionalities</h4>
                  <ul>
                    {functionalities.map((functionality, funcIndex) => (
                      <Box
                        as="li"
                        key={funcIndex}
                        sx={{
                          maxWidth: "70vw",
                        }}
                      >
                        {functionality}
                      </Box>
                    ))}
                  </ul>
                </>
              )}
              {technologies && (
                <>
                  <h4>Technologies</h4>
                  <p>{technologies}</p>
                </>
              )}
            </div>
          );
        },
      )}
    </div>
  );
}
Portfolio.propTypes = {
  data: PropTypes.array.isRequired, // Validate the 'data' prop
};
export default Portfolio;

/* eslint-disable react/prop-types */
