import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrlLinks } from "../../../api/baseURL";
import { fetchData } from "../../../fetch/fetchData";

const initialState = {
  deploymentStarted: false,
  deployed: false,
  loading: false,
  error: false,
};

export const deployToEc2 = createAsyncThunk(
  "deploy/sendDeployRequest",
  async (data) => {
    const response = await fetchData(
      `${apiUrlLinks}/start-ec2-instance/start-stop-ec2`,
    );
    return response.data;
  },
);

export const deploySlice = createSlice({
  name: "deploy",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deployToEc2.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deployToEc2.fulfilled, (state, action) => {
        state.deploymentStarted = true;
        state.loading = false;
      })
      .addCase(deployToEc2.rejected, (state, action) => {
        state.deploymentStarted = false;
        state.loading = false;
      });
  },
});

// Action creators are generated for each case reducer function
// export const {  } = deploySlice.actions;
const { reducer } = deploySlice;

export default reducer;
