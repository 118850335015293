function AboutMe({
  data: {
    fullName,
    birthLocation,
    nationality,
    familySituation,
    phoneAndWhatsapp,
    email,
    linkedin,
  },
}) {
  return (
    <div>
      <h1>{fullName}</h1>
      <h2>About Me</h2>
      <p>Birth Location: {birthLocation}</p>
      <p>Nationality: {nationality}</p>
      <h2>Contact</h2>
      <p>Phone &amp; Whatsapp: {phoneAndWhatsapp}</p>
      <p>Email: {email}</p>
      <p>
        Linkedin: <a href={linkedin}>{linkedin}</a>
      </p>
    </div>
  );
}

export default AboutMe;
