import { useEffect } from "react";
import "./styles.css";
import { Box, Button, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Ec2Icon from "../assets/Ec2Icon";
import { useSelector } from "react-redux";

// eslint-disable-next-line
function setCookie(name, value, minutes) {
  const expiryDate = new Date();
  expiryDate.setTime(expiryDate.getTime() + minutes * 60 * 1000); // Set expiration time in milliseconds
  const expires = "expires=" + expiryDate.toUTCString();
  document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

function DeployAutoWebsite(props) {
  // eslint-disable-next-line
  const navigate = useNavigate();
  const deploymentStarted = useSelector(
    (store) => store.deploy.deploymentStarted,
  );
  const deployLoading = useSelector((store) => store.deploy.loading);

  useEffect(() => {
    if (deploymentStarted) {
      setCookie("isStarting", "true", 5);
      navigate("/deploy", { state: { isStarting: true } });
    }
  }, [deploymentStarted]);

  return (
    <>
      <Button
        as="a"
        variant="contained"
        sx={{
          height: "50px",
          width: "305px",
          animation: "blink 2s linear infinite",
          display: "grid",
          gridTemplateColumns: "auto auto",
          gridColumnGap: "13px",
          color: "white",
          backgroundImage: "linear-gradient(45deg, #dd6c11, #f0850a)",
          textDecoration: 'none',
          justifyContent: "center",
          alignItems: "center",
        }}
        href={"https://auto-website.nurhesen.com/"}
      >
        {deployLoading ? (
          <>
            <CircularProgress
              className="spinner"
              color="primary"
              sx={{
                color: "white",
                height: "25px !important",
                width: "25px !important",
              }}
              variant="indeterminate"
            />

            <Box as="p" sx={{ margin: "0" }}>
              auto-website.nurhesen.com
            </Box>
          </>
        ) : (
          <>
            <Box
              sx={{
                margin: "auto", p: '0px',
              
                ">*": {
                  height: "21px",
                },
              }}
            >
              <Ec2Icon />
            </Box>
            <Box as="p" sx={{ margin: "auto", p: '0px' }}>
              auto-website.nurhesen.com
            </Box>
          </>
        )}
      </Button>
    </>
  );
}

export default DeployAutoWebsite;
