import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import data from "./data.json";
import Portfolio from "./portfolio";
import InfoIcon from "@mui/icons-material/Info";
import SchoolIcon from "@mui/icons-material/School";
import TerminalIcon from "@mui/icons-material/Terminal";
import SourceIcon from "@mui/icons-material/Source";
import BalanceIcon from "@mui/icons-material/Balance";
import PublicIcon from "@mui/icons-material/Public";
import CloseIcon from "@mui/icons-material/Close";
import ProgrammingKnowledge from "./programming-knowledge";
import AboutMe from "./about-me";
import EducationAndExperience from "./education-and-experience";
import ExtraSkills from "./extra-skills";
// import SportsEsportsIcon from "@mui/icons-material/SportsEsports";

import Languages from "./languages";
import DarkMode from "./dark-mode";
import TwoDResume from "./two-d-resume";
import SpriteAnimation from "./SpriteAnimation";

let drawerWidth = "340px";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;

  return {
    width,
    height,
  };
}

function ResponsiveDrawer(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState("");
  const [modeNameMain, setModeNameMain] = React.useState("");

  React.useEffect(() => {
    if (currentPage !== "") {
      // Add a condition to check if currentPage is not empty
      localStorage.setItem("menu", currentPage);
    }
  }, [currentPage]);

  React.useEffect(() => {
    const lastMenuItem = localStorage.getItem("menu");
    if (lastMenuItem) {
      setCurrentPage(lastMenuItem);
    } else {
      setCurrentPage("About Me");
    }
    const width = getWindowDimensions().width;
    if (width >= 600 && width < 680) drawerWidth = "240px";
  }, []);

  const menu = [
    "About Me",
    "Education and Experience",
    "Programming Knowledge",
    "Portfolio",
    "Extra Skills",
    "Languages",
    "Game-Like Resume",
  ];

  const handleDrawerToggle = () => {
    getWindowDimensions().width < 600
      ? setMobileOpen(!mobileOpen)
      : setMobileOpen(false);
  };
  const icons = [
    (color) => <InfoIcon style={{ color: color || "white" }} />,
    (color) => <SchoolIcon style={{ color: color || "white" }} />,
    (color) => <TerminalIcon style={{ color: color || "white" }} />,
    (color) => <SourceIcon style={{ color: color || "white" }} />,
    (color) => <BalanceIcon style={{ color: color || "white" }} />,
    (color) => <PublicIcon style={{ color: color || "white" }} />,
    (color) => <SpriteAnimation />,
    // (color) => <SportsEsportsIcon style={{ color: color || "white" }} />,
  ];
  const drawer = (
    <div>
      <Box
        justifyContent={"flex-end"}
        padding={"10px"}
        sx={{
          display: { xs: "flex", sm: "none" },
        }}
      >
        <CloseIcon
          style={{ color: "white", cursor: "pointer" }}
          onClick={() => handleDrawerToggle()}
        />
      </Box>

      <Box
        as="img"
        src={`/profile_2.jpg`}
        alt={"profile"}
        loading="lazy"
        width={"100%"}
        sx={{
          borderRadius: "50%",
          padding: "5%",
          filter: "drop-shadow(7px 10px 10px black)",
        }}
      />
      <List
        sx={{
          color: "var( --left-color )",
        }}
      >
        {menu.map((text, index) => (
          <ListItem
            key={text}
            disablePadding
            onKeyUp={() => (setCurrentPage(text), handleDrawerToggle())}
            onClick={() => (setCurrentPage(text), handleDrawerToggle())}
          >
            <ListItemButton id={text.toLowerCase().split(" ").join("-")}>
              <ListItemIcon>
                {icons[index](
                  currentPage === text ? "orange" : "var( --left-color )",
                )}
              </ListItemIcon>
              <ListItemText
                sx={{
                  color: currentPage === text && "orange",
                }}
                primary={text}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      {currentPage !== "Game-Like Resume" && (
        <DarkMode setModeNameMain={setModeNameMain} />
      )}
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth})` },
          ml: { sm: `${drawerWidth}` },
          backgroundImage: "var( --left-bg )",
          display: { sm: "none" },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
        }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: "100%",
              borderRight: "none",
              backgroundImage: "var( --left-bg )",
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              borderRight: "none",
              backgroundImage: "var( --left-bg )",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: "100%",
          minHeight: "100vh",
          backgroundSize: "cover",

          backgroundAttachment: "fixed",
          width: { sm: `calc(100% - ${drawerWidth})` },

          ...(modeNameMain === "dark"
            ? {
                backgroundImage: "url(/bg-black.jpg)",

                boxShadow: "inset 0 0 0 1000px #000000bf",
              }
            : {
                backgroundImage: "url(/bg-light.jpg)",
                boxShadow: "rgb(255 255 255 / 75%) 0px 0px 0px 1000px inset",
              }),
        }}
      >
        {currentPage !== "Game-Like Resume" && (
          <Box
            sx={{
              flexGrow: 1,
              p: 3,
              paddingTop: 5,
              ">*": {
                animation: "slide-page 1s forwards ease",
              },
            }}
          >
            {currentPage === "About Me" && <AboutMe data={data.about_me} />}

            {currentPage === "Education and Experience" && (
              <EducationAndExperience data={data.education_and_experience} />
            )}

            {currentPage === "Portfolio" && <Portfolio data={data.portfolio} />}

            {currentPage === "Programming Knowledge" && (
              <ProgrammingKnowledge data={data.programming_knowledge} />
            )}

            {currentPage === "Extra Skills" && (
              <ExtraSkills data={data.extra_skills} />
            )}
            {currentPage === "Languages" && <Languages data={data.languages} />}
          </Box>
        )}

        {currentPage === "Game-Like Resume" && (
          <TwoDResume getWindowDimensions={getWindowDimensions} />
        )}
      </Box>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
