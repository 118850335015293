import React from "react";
import { Box } from "@mui/material";

function circle(full) {
  if (!full) {
    return (
      <Box
        sx={{
          borderRadius: "50%",
          width: "10px",
          height: "10px",
          backgroundColor:
            "color-mix(in lch, var(--body-color) 20%, var(--body-bg))",
          marginLeft: "10px",
        }}
      ></Box>
    );
  }

  return (
    <Box
      sx={{
        borderRadius: "50%",
        width: "10px",
        height: "10px",
        backgroundColor: "var(--body-color)",
        marginLeft: "10px",
      }}
    ></Box>
  );
}

function ProgrammingKnowledge({ data }) {
  return (
    <div>
      <h1>Programming Knowledge</h1>
      {data.map(({ title, rating }, index) => {
        return (
          <div key={index}>
            <table>
              <tbody>
                <tr>
                  <td width={"120px"}>
                    <h4>{title}</h4>
                  </td>
                  <td>
                    <Box display={"flex"}>
                      {Array(7)
                        .fill(0)
                        .map((x, index) => {
                          return (
                            <span key={index}>
                              {circle(index < rating ? true : false)}
                            </span>
                          );
                        })}
                    </Box>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      })}
    </div>
  );
}

export default ProgrammingKnowledge;
